@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
    width: 15px;
    padding-right: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Handle 64C5A7 */
::-webkit-scrollbar-thumb {
    background: #64C5A7; 
    border-radius: 10px;

  }

  /*
    #64C5A7
  */
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #64C5A7; 
    border-radius: 10px;
  }